@font-face
{
  font-family: MontserratSemiBold;
  src: url('../fonts/Montserrat-SemiBold.ttf');
}
@font-face
{
  font-family: Montserrat;
  src: url('../fonts/Montserrat-Regular.ttf');
}
*{
  /* cursor: url('../img/cursor.svg'), none !important; */
}
body
{
  /* overflow: hidden; */
  font-family: 'Montserrat';
  user-select: none;
  text-transform: inherit;
}
body.fontDecrease {
  text-transform: lowercase;
}
body.fontIncrease {
  text-transform: uppercase;
}
.parent
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
.parent.register-wrapper .login-form
{
  padding-bottom: 50px;
  height: auto !important;
  padding-top: 50px;
}
.parent.register-wrapper .login .left-part
{
  overflow-y: auto;
}
.parent .login
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
.parent .login .left-part
{
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  padding: 50px 0px 0px 100px;
  display: flex;
  flex-direction: column;
}
.parent .login .left-part .logo
{
  position: relative;
  float: left;
  width: 100%;
  height: 150px;
}
.parent .login .left-part .logo img
{    
  position: relative;
  float: left;
  width: 170px;
  height: 130px;
  object-fit: cover;
  object-position: center;
}
.parent .login .left-part .login-form
{
  position: relative;
  float: left;
  width: 100%;
  height: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  /* padding-top: 100px; */
}
/* .login-form .input-field span.second-title
{
  display: none;
} */
.parent .login .left-part .login-form .title-form
{
  font-size: 25px;
  margin-bottom: 26px;
  color: #4a4a4a;
}
.parent .login .left-part .login-form .aButtonOne
{
  width: 240px;
  font-size: 13px;
  color: #428ce8;
  text-decoration: none;
  margin-bottom: 26px;
  text-align: center
}
.parent .login .left-part .login-form .rectangle
{
  width: 240px;
  height: 1px;
  background-color: #d8d8d8;
  margin-bottom: 26px;
}
.parent .login .left-part .login-form .input-field
{
  position: relative;
  float: left;
  width: auto !important;
  margin: 5px 0px 15px 0px;
  display: flex;
  flex-direction: row;
}
span.second-title
{
  width: 100px;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-right: 20px;
  min-width: 100px;
}
.parent .login .left-part .login-form .input-field select,
.parent .login .left-part .login-form .input-field input
{
  width: 240px;
  height: 36px;
  padding-left: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
}
.parent .login .left-part .login-form .loginButton
{
  position: relative;
  float: left;
  width: 100%;
  margin: 15px 0px 30px 0px;
}
.parent .login .left-part .login-form .loginButton button
{
  width: 240px;
  height: 36px;
  border-radius: 2em;
  border: none;
  background-color: #f8a900;
  color: #000;
  cursor: pointer;
}
.parent .login .left-part .login-form .go-to-register
{
  width: 240px;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.parent .login .left-part .login-form .go-to-register span
{
  width: 100%;
  font-size: 13px;
  text-decoration: none;
  margin-bottom: 20px;
  color: #4a4a4a;
}
.parent .login .left-part .login-form .go-to-register .aButton
{
  width: 240px;
  height: 36px;
  border-radius: 36px;
  background-color: #45423d;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}
.parent .login .left-part .go-to-homePage
{
  display: flex;
}
.parent .login .left-part .go-to-homePage a
{
  text-decoration: none;
  color: #4a4a4a;
  font-size: 13px;
}
.parent .login .left-part .go-to-homePage a:hover
{
  text-decoration: underline;
}
.parent .login .right-part
{
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
}
/* .parent .login .right-part img
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
} */
.register-input
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.register-input .input-field
{
  position: relative;
  float: left;
  width: calc(calc(100% / 2) - 50px) !important;
  margin-right: 25px !important;
  height: 50px;
  display: flex;
  align-items: center;
}
.right-form .register-input .input-field
{
  width: 100% !important;
}
.login.login-wrapper .register-input .input-field span.second-title,
.left-form .register-input .input-field span.second-title
{
  width: 100px;
}
.register-input .input-field span.second-title
{
  width: 160px;
}
/* .register-input .input-field input
{
  width: 60% !important;
} */
.parent .login .left-part .login-form .go-to-login
{
  width: 240px;
  height: auto;
  text-align: center;
}
.parent .login .left-part .login-form .go-to-login span
{
  font-size: 13px;
  text-decoration: none;
  margin-bottom: 20px;
  color: #4a4a4a;
}
.parent .login .left-part .login-form .go-to-login a
{
  font-size: 13px;
  color: #468ee5;
  text-decoration: none;
}
.parent-div
{
  position: relative;
  float: left;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.parent-div .left-menu
{
  position: relative;
  float: left;
  width: 350px;
  height: 100%;
  background-color: #fff;
  padding: 0px 50px;
  z-index: 1000;
}
.parent-div .left-menu .left-menu-wrapper
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  padding: 50px 0px 20px 0px;
}
.parent-div .left-menu .left-menu-wrapper .logo
{
  position: relative;
  float: left;
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.parent-div .left-menu .left-menu-wrapper .logo img
{
  position: relative;
  float: left;
  width: 170px;
  height: 130px;
  object-fit: cover;
  object-position: center;
}
.parent-div .left-menu .left-menu-wrapper .menu-user
{
  position: relative;
  float: left;
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.parent-div .left-menu .left-menu-wrapper .menu-user span.welcome
{
  position: relative;
  float: left;
  /* margin-bottom: 15px; */
  font-size: 20px;
  font-weight: 300;
  color: #2d2e2c;
  height: 10%;
}
.parent-div .left-menu .left-menu-wrapper .menu-user span.initial
{
  position: relative;
  float: left;
  width: 180px;
  background-color: #f7f8fc;
  height: 180px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 40px;
  font-weight: bold;
  color: #f8a900;
  user-select: none;
}
.parent-div .left-menu .left-menu-wrapper .menu-user p
{
  /* margin-top: 15px; */
  /* font-family: Lato; */
  height: 10%;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}
.parent-div .left-menu .profile-pricture
{
  position: relative;
  float: left;
  width: 180px;
  height: 50%;  
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.parent-div .left-menu .profile-pricture img
{
  position: relative;
  object-position: center;
  object-fit: cover;
  border-radius: 50%;
  width: 180px;
  height: 180px;
}
.parent-div .left-menu .profile-pricture form
{
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 90px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  overflow: hidden;
  cursor: pointer;
}
.filepond--list-scroller,
.filepond--panel.filepond--panel-root,
#filepond--assistant-cxl6mea1r,
.filepond--drip,
.parent-div .left-menu .profile-pricture form .form-group label.col-form-label
{
  display: none;
}
.parent-div .left-menu .profile-pricture form .form-group 
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  margin: 0px !important;
}
.parent-div .left-menu .profile-pricture form .file-upload-wrapper
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  padding: 0px;
}
.parent-div .left-menu .profile-pricture form .file-upload-wrapper .filepond--wrapper
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
.parent-div .left-menu .profile-pricture form .file-upload-wrapper .filepond--wrapper .filepond--root.filepond--hopper
{
  margin-bottom: 0px;
  height: 100% !important;
}
.parent-div .left-menu .profile-pricture form .file-upload-wrapper .filepond--wrapper .filepond--root.filepond--hopper .filepond--drop-label
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  opacity: 0 !important;
  transition: .3s linear all;
  transform: translate3d(0px, 0px, 0) !important;
  visibility: inherit !important;
  pointer-events: inherit !important;
}
.parent-div .left-menu .profile-pricture:hover form .file-upload-wrapper .filepond--wrapper .filepond--root.filepond--hopper .filepond--drop-label
{
  opacity: 1 !important;
  background-color: rgba(17,17,17, 0.7);
}
.parent-div .left-menu .profile-pricture:hover form .file-upload-wrapper .filepond--wrapper .filepond--root.filepond--hopper .filepond--drop-label label
{
  background-image: url('../img/camera.svg');
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.parent-div .left-menu .profile-pricture form .file-upload-wrapper .filepond--panel .filepond--panel-root
{
  border-radius: 0px;
}
.parent-div .left-menu .left-menu-wrapper .content-menu
{
  position: relative;
  float: left;
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
}
.parent-div .left-menu .left-menu-wrapper .content-menu .abutton
{
  position: relative;
  float: left;
  width: 180px;
  padding: 10px;  
  border-radius: 2em;
  background-color: #f8a900;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  color: #000;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}
.parent-div .left-menu .left-menu-wrapper .content-menu .abutton img
{
  position: relative;
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 8.5px;
}
.parent-div .left-menu .left-menu-wrapper .content-menu .textbutton
{
  position: relative;
  float: left;
  width: 100%;
  height: 36px;
  display: flex;
  margin-bottom: 23px;
  color: #8a8a8a;
  font-size: 14px;
  text-decoration: none;
}
.parent-div .session .session-boxes .session-box.disable,
.parent-div .left-menu .left-menu-wrapper .content-menu .abutton.disable,
.parent-div .left-menu .left-menu-wrapper .content-menu .textbutton.disable
{
  pointer-events: none;
  cursor: default;
}
.parent-div .left-menu .left-menu-wrapper .content-menu .tooltip .abutton span.tooltip-span,
.parent-div .left-menu .left-menu-wrapper .content-menu .tooltip .textbutton span.tooltip-span
{
  display: none;
}
.dashboard .tooltip
{
  position: relative;
}
.dashboard .tooltip .tooltip-span
{
  display: none;
  position: absolute;
  left: 102%;
  background-color: #2d2e2c;
  border-radius: 3px;
  color: #fff;
  width: 150px;
  padding: 7px;
  top: 25%;
}
.dashboard .tooltip:hover .tooltip-span
{
  display: inline;
}
.parent-div .session .session-boxes .session-box.disable span,
.parent-div .left-menu .left-menu-wrapper .content-menu .tooltip .abutton.disable span,
.parent-div .left-menu .left-menu-wrapper .content-menu .tooltip .textbutton.disable span
{
  display: none;
  position: absolute;
  left: 90%;
  background-color: #2d2e2c;
  border-radius: 3px;
  color: #fff;
  width: 150px;
  padding: 7px;
}
.parent-div .left-menu .left-menu-wrapper .content-menu .tooltip .abutton.disable span
{
  left: 105%;
}
.parent-div .session .session-boxes .session-box.disable span,
.parent-div .left-menu .left-menu-wrapper .content-menu .tooltip:hover .abutton.disable span,
.parent-div .left-menu .left-menu-wrapper .content-menu .tooltip:hover .textbutton.disable span
{
  display: inline;
}
.parent-div .left-menu .left-menu-wrapper .content-menu .textbutton:hover
{
  text-decoration: underline;
}

.parent-div .left-menu .left-menu-wrapper .content-menu .textbutton img
{
  position: relative;
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 15px;
}
.parent-div .right-part
{
  position: relative;
  float: left;
  width: calc(100% - 350px);
  height: 100%;
  background-color: #f7f8fc;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.parent-div .right-part .dashboard
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.parent-div .right-part span
{
  font-size: 13px;
  text-align: center;
  color: #4a4a4a;
}
.parent-div .right-part .first-title
{
  font-size: 23px;
  margin-bottom: 20px;
}
.choose-prof .checkbox
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.parent-div .right-part .topOval
{
  position: absolute;
  float: left;
  width: 400px;
  height: 400px;
  top: -50%;
  transform: translateY(50%);
  border-radius: 50%;
  border: 5px solid #f9aa00;
  opacity: .1;
}
.parent-div .right-part .rightOval
{
  position: absolute;
  float: left;
  width: 300px;
  height: 300px;
  top: 80%;
  right: -10%;
  border-radius: 50%;
  border: 5px solid #f9aa00;
  opacity: .1;
}
.parent-div .right-part .leftOval
{
  position: absolute;
  float: left;
  object-fit: contain;
  object-position: center;
  width: 300px;
  height: 300px;
  left: -10%;
  bottom: -15%;
  z-index: 100;
  border-radius: 50%;
  border: 5px solid #f9aa00;
  opacity: .1;
}
.dark .right-part .topOval,
.dark .right-part .rightOval,
.dark .right-part .leftOval
{
  border-color: #fff;
  opacity: .3;  
}
.parent-div .session
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.parent-div .session span
{
  font-size: 36px;
  font-weight: 300;
  text-align: center;
  color: #2d2e2c;
}
.parent-div .session .session-boxes
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  padding: 0px 15px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}
.parent-div .session .session-boxes .tooltip-session
{
  position: relative;
  float: left;
  width: calc(calc(100% / 3) - 20px);
  min-width: 190px;
  max-width: 300px;
  height: 200px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: .3s linear all;
  text-decoration: none;
}
.parent-div .session .session-boxes .tooltip-session .session-box
{
  
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: .3s linear all;
  border: solid 1px #d5d6da;
  text-decoration: none;
  cursor: pointer;
}
.parent-div .session .session-boxes .tooltip-session .session-box.disable
{
  pointer-events: none;
  cursor: default;
}
.parent-div .session .session-boxes .tooltip-session .session-box .tooltip-span
{
  display: none;
  position: absolute;
  left: 90%;
  background-color: #2d2e2c;
  border-radius: 3px;
  color: #fff;
  width: 150px;
  padding: 7px;
  font-size: 14px;
}
.parent-div .session .session-boxes .tooltip-session:hover .session-box.disable .tooltip-span
{
  display: inline;
}
.parent-div .session .session-boxes .session-box:hover
{
  border: solid 1px #f8a900;
  background-color: #fff;
}
.parent-div .session .session-boxes .tooltip-session .session-box .img
{
  position: relative;
  float: left;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("../img/eye.svg");
}
.parent-div .session .session-boxes .tooltip-session:nth-child(2) .session-box .img
{
  background-image: url("../img/brain.svg");
}
.parent-div .session .session-boxes .tooltip-session:nth-child(3) .session-box .img
{
  background-image: url("../img/table.svg");
}
.parent-div .session .session-boxes .tooltip-session:nth-child(4) .session-box .img
{
  background-image: url("../img/eye.svg");
}
.parent-div .session .session-boxes .session-box img
{
  transition: .3s linear all;
}
.parent-div .session .session-boxes .tooltip-session:nth-child(1) .session-box:hover .img
{
  background-image: url("../img/eyeColor.svg");
}
.parent-div .session .session-boxes .tooltip-session:nth-child(2) .session-box:hover .img
{
  background-image: url("../img/brainColor.svg");
}
.parent-div .session .session-boxes .tooltip-session:nth-child(3) .session-box:hover .img
{
  background-image: url("../img/tableColor.svg");
}
.parent-div .session .session-boxes .tooltip-session:nth-child(4) .session-box:hover .img
{
  background-image: url("../img/eyeColor.svg");
}
.parent-div .session .session-boxes .session-box p
{
  font-size: 14px;
  color: #4a4a4a;
  margin-top: 30px;
}
.parent-div .session .session-boxes .session-box:hover p
{
  color: #f8a900;
}
.backButton 
{
  margin-bottom: 70px;
}
.backButton span,
.backButton a
{
  font-size: 14px;
  color: #4a4a4a;
  text-decoration: none;
  cursor: pointer;
}
.backButton a:hover
{
  text-decoration: underline; 
}
.parent-div .upgrade
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.parent-div .upgrade .firstSpan
{
  font-size: 36px;
  font-weight: 300;
  text-align: center;
  color: #4a4a4a;
}
.parent-div .upgrade p
{
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  color: #4a4a4a;
}
.parent-div .upgrade .secondSpan
{
  font-size: 20px;
  text-align: center;
  margin-top: 30px;
  color: #4a4a4a;
}
.parent-div .upgrade .thirdSpan
{
  font-size: 14px;
  text-align: center;
  margin-top: 30px;
  color: #4a4a4a;
}
.parent-div .profile
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.parent-div .profile .title
{
  color: #2d2e2c;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.parent-div .profile form
{
  width: 100%;
  /* height: 100%; */
  padding: 0px 100px;
  margin-top: 50px;
}
.parent-div .profile form .left-form
{
  position: relative;
  float: left;
  width: 70%;
  height: auto;
}
.parent-div .profile form .right-form .input-field input,
.parent-div .profile form .left-form .register-input .input-field input 
{
  height: 36px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  /* margin-bottom: 15px; */
  padding-left: 12px;
  width: 100%;
}
.parent-div .profile form .right-form .input-field input::placeholder,
.parent-div .profile form .left-form .register-input .input-field input::placeholder
{
  color: #3f3838;
  /* font-family: Lato; */
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.parent-div .profile form .left-form .personalTitle,
.parent-div .profile form .right-form .subTitle
{
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d2e2c;
  margin-bottom: 28px;
}
.parent-div .profile form .right-form
{
  position: relative;
  float: left;
  width: 30%;
  height: auto;
}
.parent-div .profile form .profile-button
{
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.parent-div .profile form .profile-button .loginButton
{
  margin-right: 20px;
  color: #fff;
}
.parent-div .profile form .profile-button .loginButton:first-child span,
.parent-div .profile form .profile-button .loginButton:first-child button
{
  width: 240px;
  height: 36px;
  border: none;
  border-radius: 36px;
  background-color: #f8a900;
  color: #000 !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
}
.parent-div .profile form .profile-button .loginButton:nth-child(2) button
{
  width: 240px;
  height: 36px;
  border: none;
  border-radius: 36px;
  background-color: #f8a900;
  color: #000;
  cursor: pointer;
}
.parent-div .profile form .profile-button .loginButton:last-child button
{
  width: 240px;
  height: 36px;
  border: none;
  border-radius: 36px;
  background-color: #d0021b;
  color: #000;
  cursor: pointer;
}
.parent-div .progress
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.parent-div .progress .title
{
  color: #2d2e2c;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 30px;
}
.parent-div .progress .progress-charts
{
  position: relative;
  float: left;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.parent-div .progress .progress-charts .second-chart .chart-title,
.parent-div .progress .progress-charts .first-chart .chart-title
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px !important;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d2e2c;
  margin-bottom: 20px;
}
.parent-div .progress .progress-charts .first-chart
{
  width: 50%;
  height: 50%;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px 20px 20px 0px;
}
.parent-div .progress .progress-charts .second-chart
{
  width: 50%;
  height: 50%;
  background-color: #fff;
  padding: 20px 20px 20px 0px;
}
.parent-div .settings
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.parent-div .settings .title
{
  color: #2d2e2c;
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 30px;
}
.parent-div .settings .settings-session
{
  /* position: relative;
  float: left; */
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.parent-div .settings .settings-session span.first-title
{
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2d2e2c;
  margin-top: 15px;
  margin-bottom: 15px;
}
.parent-div .settings .settings-session span.second-title
{
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  color: #2d2e2c;
}
.parent-div .settings .settings-session .checkbox
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent-div .settings .settings-session button
{
  width: 200px;
  height: 50px;
  background-color: #f8a900;
  color: #000;
  margin-top: 50px;
  border: none;
  border-radius: 2em;
  cursor: pointer;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  font-size: 25px;
}
.parent-div .settings .settings-session button.custom-button
{
  left: 0%;
  transform: translate(0%)
}
.parent-session
{
  position: relative;
  float: left;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
.parent-session .info
{
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  background-color: white;
  cursor: pointer;
  padding: 7px;
  transition: .3s linear all;
}
.parent-session .close
{
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  background-color: white;
  cursor: pointer;
  padding: 7px;
  transition: .3s linear all;
  z-index: 9999;
}
.parent-session .symbol
{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 10px;
  transition: .3s linear all;
}
.parent-session .symbol img
{
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.parent-session .symbol-text
{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 90px;
  width: auto;
  height: 40px;
  border-radius: 7px;
  background-color: #fff;
  cursor: auto;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s linear all;
}
/* .parent-session.active .close, */
.parent-session.active .info,
.parent-session.active .symbol,
.parent-session.active .symbol-text
{
  opacity: 0.4;
  cursor: default;
}

.parent-session .info-parent
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .3s linear all;
  width: 0px;
  height: 0px;
  background-color: #fff;
  border-radius: 20px;
  opacity: 0;
  overflow: hidden;
  padding: 0px 30px;    
  border: 1px solid rgba(134, 126, 126, 0.7);
}
.parent-session .info-parent.active
{
  opacity: 1;
  width: 650px;
  height: 500px;
  border-radius: 20px;
}
.parent-session .info-parent .image
{
  position: relative;
  float: left;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.parent-session .info-parent .content
{
  position: relative;
  float: left;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.parent-session .info-parent .content span
{
  width: 100%;
  font-family: Montserrat;
  font-size: 0px;
  transition: .3s ease-in-out all;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #2d2e2c;
  margin-bottom: 15px;
  position: relative;
}
.parent-session .info-parent.active .content span
{
  font-size: 36px;
}
.parent-session .info-parent .content .buttons button
{
  margin-right: 20px;
}
.parent-session .info-parent .content button
{
  width: 160px;
  height: 33px;
  border: none;
  border-radius: 36px;
  background-color: #f8a900;
  color: #fff;
  cursor: pointer;
}
.parent-session .info-parent .content img
{
  position: relative;
  float: left;
  width: 40px;
  height: 40px;
}
.profile .change-password
{
  position: absolute;
  width: 500px;
  height: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  transition: .6s ease-in-out all;
  z-index: 999;
  margin-top: 40px;
  border: 1px solid #e7e7e7;
  display: none;
  padding: 50px;
}
.profile .change-password.active
{
  display: block;
}
.profile .change-password img
{
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.profile .change-password form
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;  
  margin-top: 0px;
  padding: 0px;
}
.profile .change-password form .change-password-input
{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.profile .change-password form .change-password-input .input-field
{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}
.profile .change-password form .change-password-input .input-field input
{
  height: 35px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  padding-left: 12px;
  width: 100%;
}
.profile .change-password form .change-password-input button
{
  position: relative;
  float: left;
  width: 160px;
  height: 35px;
  /* padding: 15px 0px; */
  background-color: #f8a900;
  color: #fff;
  border: none;
  border-radius: 2em;
  margin-top: 20px;
  cursor: pointer;
}
.checkbox-inside {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 15px;
}

/* Hide the browser's default radio button */
.checkbox-inside input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 5px;
  transform: translateY(-50%);
}

/* On mouse-over, add a grey background color */
.checkbox-inside:hover input ~ .checkmark {
  background-color: #f8a900;
}

/* When the radio button is checked, add a blue background */
.checkbox-inside input:checked ~ .checkmark {
  background-color: #f8a900;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkbox-inside:hover input ~ .checkmark:before {
  content: url('../img/tick.svg');
  position: absolute;
  display: none;
  width: 15px;
  height: 15px;
}
.checkbox-inside:hover input ~ .checkmark:after {
  content: url('../img/tick.svg');
  position: absolute;
  display: none;
  width: 15px;
  height: 15px;
}
.checkmark:after {
  content: url('../img/tick.svg');
  position: absolute;
  display: none;
  width: 15px;
  height: 15px;
}
/* Show the indicator (dot/circle) when checked */
.checkbox-inside input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.checkbox-inside .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.symbols
{
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.symbols img
{
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

/* DARK MODE */
/* DARK MODE */
/* DARK MODE */
/* DARK MODE */
/* DARK MODE */
/* DARK MODE */
/* DARK MODE */
/* DARK MODE */
.fontSize{
  position: absolute;
  left: 0;
  top: 155px;
  width: 30px;
  height: auto;
  background-color: RGBA(247,248,252, 1);
  cursor: pointer;
  z-index: 999999999999999999;
  transition: .3s ease-in-out;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0px 3px;
}
.fontSize span
{
  margin: 0px 10px;
}
.fontSize img
{
  width: 30px;
  height: 30px;
}
.fontSize img:first-child
{
  transform: rotate(-90deg);
}
.fontSize img:nth-child(3)
{
  transform: rotate(90deg);
}
.language{
  position: absolute;
  left: 0;
  top: 55px;
  width: 30px;
  height: 40px;
  background-color: RGBA(247,248,252, 1);
  cursor: pointer;
  z-index: 999999999999999999;
  transition: .3s ease-in-out;
  align-items: center;
  display: flex;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.language span
{
  text-transform: uppercase;
}
.darkmode
{
  position: absolute;
  left: 0;
  top: 105px;
  width: 30px;
  height: 40px;
  background-color: RGBA(247,248,252, 1);
  cursor: pointer;
  z-index: 999999999999999999;
  transition: .3s ease-in-out;
  align-items: center;
  display: flex;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.darkmode img
{
  width: 25px;
  height: 25px;
}
.darkmode.dark
{
  background-color: RGBA(247,248,252, .5);
}
.darkmode.dark img
{
  content: url('../img/moon-white.svg')
}
.darkmode:hover
{
  width: 50px;
}
.dark .left-menu
{
  background-color: rgb(19, 20, 23);
}
.dark .parent .login,
.dark .right-part
{
  background-color: rgb(30, 31, 35);
}
.dark .parent-div .settings .title,
.dark .parent-div .settings .settings-session span.first-title,
.dark .checkbox-inside,
.dark .backButton a,
.dark .profile .title,
.dark .profile form .left-form .personalTitle, .parent-div .profile form .right-form .subTitle,
.dark .left-menu .left-menu-wrapper .menu-user p,
.dark .left-menu .left-menu-wrapper .menu-user span.welcome,
.dark .parent-div .settings .settings-session span.second-title,
.dark .parent-div .right-part span,
.dark .parent-div .upgrade span,
.dark .parent-div .upgrade p,
.dark .parent-div .profile form .right-form .input-field input::placeholder,
.dark .parent-div .profile form .left-form .register-input .input-field input::placeholder,
.dark .parent .login .login-form span.title-form,
.dark .parent .login .login-form span.second-title,
.dark .parent .login .login-form span.register-account
{
  color: #8a8a8a;
}
input::placeholder.text-change
{
  font-size: 10px !important;
}
.dark .parent-div .right-part span.checkmark
{
  background-color: #f8a900;  
}
.parent-div .right-part .dashboardButton
{
  width: 500px;
  height: 62px;
  border-radius: 72px;
  background-color: #f8a900;
  color: #000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 28px;
  padding: 0px 40px;
  margin: 20px 0px;
  cursor: pointer;
}
/* .dark .profile form .profile-button .loginButton:first-child button,
.dark .profile form .profile-button .loginButton:nth-child(2) button,
.dark .left-menu .left-menu-wrapper .content-menu .abutton,
.dark .parent-div .settings .settings-session button,
.dark .checkbox-inside input:checked ~ .checkmark,
.dark .checkbox-inside:hover input ~ .checkmark,
.dark .parent-div .right-part .dashboardButton,
.dark .profile .change-password form .change-password-input button
{
  color: #8a8a8a;
  background-color: #2d2a50;
} */
.dark .profile form .profile-button .loginButton:last-child button
{
  color: #8a8a8a;
  background-color: #690000;
}
.dark .left-menu
{
  -webkit-box-shadow: 10px 0px 58px -42px rgba(19,29,39,1);
  -moz-box-shadow: 10px 0px 58px -42px rgba(19,29,39,1);
  box-shadow: 10px 0px 58px -42px rgb(0, 0, 0);
}
.dark .parent-div .session .session-boxes .session-box
{
  border: solid 1px #8a8a8a;
}
.dark .parent-div .session .session-boxes .session-box:hover
{
  background-color: rgb(19, 20, 23);
  border: solid 1px #f8a900;
}
.dark .parent-div .session .session-boxes .session-box p
{
  color: #8a8a8a;
}
.dark .parent-div .session .session-boxes .session-box:hover p
{
  color: #f8a900;
}
.dark .parent-div .profile form .right-form .input-field input, 
.dark .parent-div .profile form .left-form .register-input .input-field input,
.dark .profile .change-password form .change-password-input .input-field input
{
  color: #8a8a8a;
  background-color: rgb(19, 20, 23);
  border: none;
  border-bottom: 1px solid #f8a900;
}
.dark .profile .change-password
{
  background-color: rgba(30, 31, 35);
  -webkit-box-shadow: 10px 10px 71px -22px rgb(0, 0, 0);
  -moz-box-shadow: 10px 10px 71px -22px rgb(0, 0, 0);
  box-shadow: 10px 10px 71px -22px rgb(0, 0, 0);
  border: none;
}
.popup
{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  backdrop-filter: blur(70px);
}
.recharts-surface
{
  position: relative;
  float: left;
  width: 100%;
  height: 90%;
}
.slider a.previousButton, 
.slider a.nextButton
{
  display: none !important;
}
.slider
{
  height: 100% !important;
}



/* CUSTOMIZED */
.checkbox.customized
{
  display: flex !important;
  flex-direction: column;
}
.checkbox.customized .input-field
{
  position: relative;
  float: left;
  /* width: calc(100% / 3 - 20px); */
  width: 100%;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  height: 40px;
}
.checkbox.customized .input-field span
{
  width: 200px !important;
  min-width: 200px !important;
}
.checkbox.customized .input-field .flexbox-fix span
{
  width: auto !important;
  min-width: auto !important;
}
.checkbox.customized .input-field .flexbox-fix input
{
  height: auto !important;
  border-radius: 0 !important;
  border: 0px !important;
  background-color: transparent !important;
  padding-left: 0px !important;
  width: 80% !important;
}
.sketch-picker
{
  display: flex;
  flex-direction: column-reverse;
  padding: 0px 10px 10px !important;
}
.checkbox.customized .input-field input,
.checkbox.customized .input-field select
{
  height: 36px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  padding-left: 12px;
  width: 100%;
}
.checkbox.customized .input-field .swatch
{
  width: 100%;
  height: 36px;
  margin-bottom: 15px;
  border-radius: 6px;
  border: solid 1px #dddddd;
}
.checkbox.customized .input-field .swatch .color
{
  width: 100%;
  height: 100%;
  border: 1px solid #000;
}
/* COLOR PICKER */
.color 
{
  width: 36px;
  height: 14px;
  border-radius: 2px;
}
.swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
}
.popover {
  position: absolute;
  z-index: 2;
}
.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.disable
{
  display: none;
}
.hermianopia.active,
.hermianopia-kids.active,
.rp-version.active
{
  display: block;
}
.customized-element.active,
.rp-version.active,
.rp-version.active .standard.active
{
  width: 100%;
}

.customized-element.active form,
.rp-version.active .standard.active .settings form
{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.customized-element.active form .settings-session,
.customized-element.active form .target-preview,
.rp-version.active .standard.active .settings .settings-session,
.rp-version.active .standard.active .settings .target-preview
{
  width: 50% !important;
  padding: 20px;
  /* display: block; */
}
.target-preview
{
  position: relative;
  float: left;
}
.customized-element.active form .target-preview .target-box
{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: none;
}
.target-preview .target-box
{
  position: absolute;
  border: 2px solid #d5d6da;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popover
{
  z-index: 101;
}

.warning-circle.is-invalid
{
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #b94a48;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.input-control.is-invalid select,
.input-control.is-invalid input
{
    border-color: #b94a48 !important;
}
.warning-circle 
{
    width: 0;
    height: 0;
}
.warning-circle.is-invalid img
{
    position: relative;
    width: 17px;
    height: auto;
}
.warning-tooltip
{
  display: none;
  position: absolute;
  left: 100%;
  background-color: #b94a48;
  border-radius: 3px;
  color: #fff;
  width: 200px;
  padding: 7px;
  transform: translateX(-100%);
  top: -50px;
  z-index: 9999;
}
.warning-tooltip p
{
    color: #fff;
}
.invalid-tooltip .warning-tooltip
{
  display: inline;
}
.input-form.input-control.is-invalid
{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}
.parent-popup
{
  position: absolute;
  background-color: rgba(156, 164, 181, 0.5);
  z-index: 10;
  width: 100%;
  height: 100vh;
  display: none;
}
.parent-popup.active
{
  display: block;
}
.delete-popup
{
  position: absolute;
  width: auto;
  height: auto;
  box-shadow: 0 0 40px 0 rgba(162, 162, 162, 0.16);
  background-color: #fff;
  z-index: 9999;
  padding: 30px;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
}
.delete-popup .popup-button
{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}
.delete-popup .popup-button .loginButton
{
  width: auto;
  margin: 0px 10px;
}
.delete-popup .popup-button .loginButton button
{
  width: 80px;
  height: 25px;
  background-color: #f8a900;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}


/* Endrit css  */

.canvas {
  background-position: center;
  background-size: contain;
}